<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 py-2>
        <HiveDetail v-if="hive._id" v-bind:storage="hive" :key="hive._id" />
      </v-flex>
      <v-flex xs12 py-2>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 py-2>
        <v-layout wrap justify-start>
          <v-flex xs12 text-left>
            <span class="itemHeading"> Hive Events </span>
          </v-flex>
          <template v-for="(item, i) in events">
            <v-flex xs12 sm6 md4 xl3 :key="i" py-2 pa-sm-2>
              <HiveEvents v-bind:storage="item" />
            </v-flex>
          </template>
          <template v-if="events.length < 1">
            <span class="itemKey"> No data available </span>
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-2>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 py-2>
        <v-layout wrap justify-start>
          <v-flex xs12 text-left>
            <span class="itemHeading"> Hive Location </span>
          </v-flex>
          <v-flex xs12 py-2 pa-sm-2>
            <Map v-if="hive._id" :key="hive._id" v-bind:storage="hive" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import HiveDetail from "./hiveDetails";
import HiveEvents from "./hiveEvents";
import Map from "./map";
export default {
  props: ["id"],
  components: {
    HiveDetail,
    HiveEvents,
    Map,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      hive: {},
      events: {},
      userType: localStorage.getItem("userType"),
    };
  },
  beforeMount() {
    this.getData();
    this.getEvents();
  },
  methods: {
    getData() {
      this.appLoading = true;
      var link = "/colony/adminget";
      if (this.userType != "Admin") link = "/colony/get";
      axios({
        method: "GET",
        url: link,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hive = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getEvents() {
      this.appLoading = true;
      var link = "/event/adminlastevents";
      if (this.userType != "Admin") link = "/event/lastevents";
      axios({
        method: "GET",
        url: link,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          colony: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.events = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>