<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-3>
              <div id="map"></div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCaccNxbzwR9tMvkppT7bT7zNKjChc_yAw&libraries=visualization,drawing">
</script>
<script>
var marker;
export default {
  props: ["storage"],
  data() {
    return {
      map: "",
      mapData: {},
    };
  },
  mounted() {
    this.mapData = this.storage;
    this.initMap();
  },
  methods: {
    initMap() {
      var vm = this;
      var mapCanvas = document.getElementById("map");
      var mapOptions = {
        center: new google.maps.LatLng(
          this.mapData.location[1],
          this.mapData.location[0]
        ),
        zoom: 18,

        mapTypeId: google.maps.MapTypeId.TERRAIN,
      };
      vm.map = new google.maps.Map(mapCanvas, mapOptions);
      var lat = this.mapData.location[1];
      var lon = this.mapData.location[0];
      var latlngset = new google.maps.LatLng(lat, lon);
      var icon = {
        url: "http://arhiveadmin.leopardtechlabs.com/img/orangemarker.png", // url
        scaledSize: new google.maps.Size(20, 32), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0), // anchor
      };
      marker = new google.maps.Marker({
        map: vm.map,
        position: latlngset,
        icon: icon,
      });
    },
  },
};
</script>