var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.name == 'xs' ||
      _vm.$vuetify.breakpoint.name == 'sm' ||
      _vm.$vuetify.breakpoint.name == 'md'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '75vw'
        : '50vw'},model:{value:(_vm.imageViewerDialog),callback:function ($$v) {_vm.imageViewerDialog=$$v},expression:"imageViewerDialog"}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('ImageViewer',{attrs:{"storage":_vm.storage.doc.photo},on:{"stepper":_vm.winStepper}})],1)],1)],1)],1),_c('v-card',{attrs:{"outlined":"","height":"100%"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":"","fill-height":""}},[_c('v-flex',{staticStyle:{"cursor":"pointer"},attrs:{"xs3":"","align-self-center":""}},[(_vm.storage.doc.photo)?_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.doc.photo,"height":"100px","contain":""},on:{"click":function($event){_vm.imageViewerDialog = true}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#FDCE48"}})],1)]},proxy:true}],null,false,2578572492)}):_c('span',{staticClass:"itemKey"},[_vm._v("No Image")])],1),_c('v-flex',{attrs:{"xs9":"","align-self-center":"","pl-2":""}},[_c('router-link',{attrs:{"to":'/Event/' + _vm.storage.doc._id}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticClass:"itemKey"},[_vm._v("Type")]),_c('span',{staticClass:"itemValue pl-2"},[_c('span',[_vm._v(_vm._s(_vm.storage._id))])])]),_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticClass:"itemKey"},[_vm._v("Date")]),_c('span',{staticClass:"itemValue pl-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.storage.doc.eventdate))+" ")])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }