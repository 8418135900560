<template>
  <div class="fill-height">
    <v-dialog
      v-model="imageViewerDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageViewer
              v-bind:storage="storage.doc.photo"
              @stepper="winStepper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-card outlined height="100%">
      <v-layout wrap justify-center pa-2 fill-height>
        <v-flex xs3 align-self-center style="cursor: pointer">
          <v-img
            v-if="storage.doc.photo"
            :src="mediaURL + storage.doc.photo"
            height="100px"
            contain
            @click="imageViewerDialog = true"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="#FDCE48"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <span v-else class="itemKey">No Image</span>
        </v-flex>
        <v-flex xs9 align-self-center pl-2>
          <router-link :to="'/Event/' + storage.doc._id">
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span class="itemKey">Type</span>
                <span class="itemValue pl-2">
                  <span>{{ storage._id }}</span>
                </span>
              </v-flex>
              <v-flex xs12 text-left>
                <span class="itemKey">Date</span>
                <span class="itemValue pl-2" style="font-size: 14px">
                  {{ formatDate(storage.doc.eventdate) }}
                </span>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      imageViewerDialog: false,
    };
  },
  methods: {
    winStepper(windowData) {
      this.imageViewerDialog = windowData.imageViewer;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>