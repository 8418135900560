<template>
  <div>
    <v-dialog
      v-model="imageViewerDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageViewer v-bind:storage="storage.photo" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Hive Details </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-space-between pt-2>
      <v-flex xs6 sm2 xl1 align-self-start style="cursor: pointer">
        <v-img
          v-if="storage.photo"
          :src="mediaURL + storage.photo"
          height="100px"
          cover
          @click="imageViewerDialog = true"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#FDCE48"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-flex>
      <v-flex xs6 sm2 xl3>
        <vue-qrcode :value="storage.tagid" />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Hive ID</span> <br />
        <span class="itemValue">
          {{ storage.colonyidentifier }}
          <span v-if="!storage.colonyidentifier"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Hive Status</span> <br />
        <span class="itemValue">
          {{ storage.colonystatus }}
          <span v-if="!storage.colonystatus">-</span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Hive Source</span> <br />
        <span class="itemValue">
          {{ storage.source }}
          <span v-if="!storage.source"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Queen</span> <br />
        <span class="itemValue">
          <span v-if="storage.hasqueen">
            <v-icon color="green">mdi-check</v-icon>
            {{ storage.queenid }}
          </span>
          <span v-else>
            <v-icon color="red">mdi-close</v-icon>
            No Queen
          </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2 v-if="userType == 'Admin'">
        <span class="itemKey">Hive Owned By</span> <br />
        <span v-if="storage.user" class="itemValue">
          <router-link :to="'/Admin/Users/' + storage.user._id">
            {{ storage.user.username }}
          </router-link>
          <span v-if="!storage.user.username"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Honey Type</span> <br />
        <span class="itemValue">
          {{ storage.honeytype }}
          <span v-if="!storage.honeytype"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Created Date</span><br />
        <span v-if="storage.createddate" class="itemValue">
          {{ formatDate(storage.createddate) }}
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Status</span><br />
        <span class="itemValue">
          <span v-if="storage.islate">Late</span>
          <span v-else>Ok</span>
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueQrcode from "vue-qrcode";
export default {
  props: ["storage"],
  components: {
    VueQrcode,
  },
  data() {
    return {
      imageViewerDialog: false,
      userType: localStorage.getItem("userType"),
    };
  },
  methods: {
    winStepper(windowData) {
      this.imageViewerDialog = windowData.imageViewer;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>